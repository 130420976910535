<template>
  <div class="about">
    <div class="about_max">
      <el-tabs type="border-card" style="height: 800px">
        <el-tab-pane label="视频查看" v-loading="loading"
    element-loading-text="拼命加载中....."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(250, 250, 250, 0.8)">
          <div class="header">
            <div class="ss">
              <el-date-picker
                value-format="yyyy-MM-dd"
                @change="handleDateChange"
                unlink-panels
                v-model="Soudate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :style="{ width: '300px' }"
              >
              </el-date-picker>
            </div>
            <div class="ss">
              <el-select
                v-model="creatorName"
                filterable
                clearable 
                placeholder="请选择门店前台"
                @change="newgetvideo"
              >
                <el-option
                  v-for="item in videolist"        
                  :label="item.creatorName"
                  :value="item.creatorName"
                  
                >
                </el-option>
              </el-select>
            </div>
            <div class="ss">
              <el-button type="primary" @click="getvideolist">查 询</el-button>
            </div>
          </div>

          <div class="">
            <!-- <ul>
              <li v-for="video in videos" :key="video.id">
                <video-player
                  :options="videoPlayerOptions"
                  ref="videoPlayer"
                  @ended="playNextVideo(video.id)"
                >
                  <source :src="video.url" type="video/mp4" />
                </video-player>
                <p>{{ video.title }}</p>
              </li>
            </ul> -->
            <el-table :data="videolist" style="width: 100%" height="600">
              <!-- <el-table-column
                label="流程流水号"
                prop="serialNumber"
                min-width="100"
              > -->
              </el-table-column>
              <el-table-column
                :label="(this.apitype=='卫生视频'?'前台名称':'门店名称')"
                :prop="(this.apitype=='卫生视频'?'creatorName':'sellerName')"
                
              ></el-table-column>
              <el-table-column
                label="流程发起日期"
                prop="approvalDay"
              ></el-table-column>
              <el-table-column
                label="视频列表"
                prop="videoPreviewList"
                min-widht="100px"
              >
                <template slot-scope="{ row }">
                  <div
                    v-for="(video, index) in row.videoPreviewList"
                    :key="index"
                   
                  >
                    <el-row :gutter="10" style="margin-bottom: 10px" >
                      <el-col :span="10"
                        ><a
                          class="el-button--text"
                          style="display: inline"
                          @click="playVideo(video.videoUrl)"
                          >查看</a
                        >
                      </el-col>
                      <!-- <el-col :span="4"
                        >视频状态: {{ mapVideoState(video.state) }}</el-col
                      > -->
                      <el-col :span="4">
                        <el-popconfirm
                          title="确定删除？"
                          @confirm="handleDelete(video)"
                          placement="top-end"
                        >
                          <el-button
                            size="mini"
                            type="danger"
                            icon="el-icon-delete"
                            slot="reference"
                            >删除</el-button
                          >
                        </el-popconfirm></el-col
                      >
                    </el-row>
                  </div>
                  <div v-if="row.url!=null">
                        <a
                          class="el-button--text"
                          style="display: inline"
                          @click="playVideo(row.url)"
                          >查看</a
                        >
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- Pagination -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20,]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next"
              :total="total"
            />
            <!-- Video Player Modal -->
            <el-dialog
              :visible="videoModalVisible"
              @close="closeVideoModal"
              title="视频播放"
            >
              <!-- <video
              @click="toggleFullScreen"
              ref="myVideo"
                :src="currentVideoUrl"
                controls
                width="100%"
                min-height="100%"
              ></video> -->
              <video 
                @click="toggleFullScreen"
                ref="myVideo"
                :src="currentVideoUrl"
                controls
                width="100%"
                min-height="100%">
                  <source :src="currentVideoUrl" type="video/mp4">
                  <source :src="currentVideoUrl" type="video/ogg">
                  您的浏览器不支持 video 标签。
              </video>
            </el-dialog>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import api from "../api/index";
export default {
  components: {},
  data() {
    return {
      // 日期起
      Soudate: "",
      // 前台
      reception: [],
      // 视频
      videolist: [],
      total: 0,
      // 页数
      currentPage: 1,
      // 每页条数
      pageSize: 10,
      // 流水号
      serialNumber: "",
      // 前台
      creatorName: "",
      // 数据id
      dataId: "",
      // 开始时间
      startDay: "",
      // 结束时间
      endDay: "",
      videoModalVisible: false,
      currentVideoUrl: "",
      loading: true,
      apitype:'',
    };
  },
  created() {
    this.dataId = this.$route.query.DataId;
    this.apitype=this.$route.query.ApiText
    console.log(this.dataId, "<-我的dataid");
    this.getvideolist();
  },
  methods: {
    handleDateChange(E) {
      if (E) {
        this.startDay = E[0];
        this.endDay = E[1];
      } else {
        this.startDay = "";
        this.endDay = "";
      }
    },
    mapVideoState(state) {
      return state === 1 ? "合规" : "不合格";
    },
    playVideo(videoUrl) {
      this.currentVideoUrl = videoUrl;
      this.videoModalVisible = true;
    },
    handleDelete(item) {
      api({
        url: "/OSS/deleteVideo",
        method: "delete",
        params: {
          videoUrl: item.videoUrl,
        },
      }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });

        this.getvideolist();
      });
    },
    // Handle pagination events
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;

      this.getvideolist();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.getvideolist();
    },
    closeVideoModal() {
      this.videoModalVisible = false;
      this.currentVideoUrl = "";
    },
    getvideolist() {
      // 封装视频列表请求参
      const videocan = {
        // 页数
        currentPage: this.currentPage,
        // 每页条数
        pageSize: this.pageSize,
        // 流水号
        serialNumber: this.serialNumber,
        // 前台
        creatorName: this.creatorName,
        // 数据id
        dataId: this.dataId,
        // 开始时间
        startDay: this.startDay,
        // 结束时间
        endDay: this.endDay,
      };
      if (this.apitype == "卫生视频") {
        api.post("/HygieneExamine/getExamineList", videocan).then((res) => {
          this.videolist = res.rows;
          this.total = res.total;
          this.loading = false;
        });
      } else {
        api.post(`/LinenVideo/selectLinenVideo?dataId=${this.dataId}`).then((res) => {
          this.videolist = res.data;
          this.total=res.data.length==null? 0:res.data.length
          this.loading = false;
          console.log(this.videolist);
        });
      }
    },
    // 默认全屏播放
    toggleFullScreen() {
      const videoElement = this.$refs.myVideo;

      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // 如果当前不在全屏状态，则请求全屏
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          // Firefox
          videoElement.mozRequestFullScreen();
        } else if (videoElement.webkitRequestFullscreen) {
          // Chrome, Safari and Opera
          videoElement.webkitRequestFullscreen();
        } else if (videoElement.msRequestFullscreen) {
          // IE/Edge
          videoElement.msRequestFullscreen();
        }
      } else {
        // 如果当前在全屏状态，则退出全屏
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
    // 监听变化重新获取
    newgetvideo() {
      this.getvideolist();
    },
  },
};
</script>
<style lang="scss" soped>
.about {
  // height: 100vh;
  box-sizing: border-box;
}
.about_max {
  width: 95%;
  margin: 20px auto;
  box-sizing: border-box;
}
.header {
  display: flex;
  flex-wrap: wrap;
  .ss {
    // width: 200px;
    margin-left: 20px;
    margin-top: 8px;
  }
}

.el-dialog {
  height: 500px;
  video {
    height: 100%;
    height: 380px;
  }
}
.el-pagination {
  margin-top: 4px;
}
</style>